import React from "react";
import { Helmet } from "react-helmet";
import Img from "gatsby-image";
import { graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Layout from "../components/layout";

const parallax = {
    backgroundImage: 'url(../1.jpg)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const credit = {
    background: 'linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.6)), url(../recvPCyfBxT5tu2QV.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    lineHeight: '2',
};

export default ({ data }) => (
    <div>
        <Layout>
        <Helmet>
            <title>{data.title.data.title} | Oberlin Photo Stories</title>

            <meta name="title" content={data.title.data.title} />
            <meta name="description" content="Since the first class moved into Afrikan Heritage House in 1972, it has been a place of black cultural expression and tradition." />

            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://photo-stories.oberlin.edu/welcome-to-the-house/" />
            <meta property="og:title" content={data.title.data.title} />
            <meta property="og:description" content="Since the first class moved into Afrikan Heritage House in 1972, it has been a place of black cultural expression and tradition." />
            <meta property="og:image" content="https://photo-stories.oberlin.edu/1.jpg" />
            <meta property="og:image:width" content="850" />
            <meta property="og:image:height" content="598" />
            <meta property="og:image:alt" content={data.hero.data.alt} />
            <meta property="fb:app_id" content="1571317633107125" />

            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://photo-stories.oberlin.edu/welcome-to-the-house" />
            <meta property="twitter:title" content={data.title.data.title} />
            <meta property="twitter:description" content="Since the first class moved into Afrikan Heritage House in 1972, it has been a place of black cultural expression and tradition." />
            <meta property="twitter:image" content="https://photo-stories.oberlin.edu/1.jpg" />
            <meta property="twitter:image:alt" content={data.hero.data.alt} />
        </Helmet>
        {/* hero */}
        <header>
            <div className="wrapper">
                <div style={parallax} className="hero parallax" role="img" aria-label={data.hero.data.alt}></div>
                <div className="title text-center">
                    <h1 className="display-3 text-light">{data.title.data.title}</h1>
                    <p className="lead text-light">- {data.subtitle.data.title} -</p>
                    <a href="#ss" aria-label="Skip to main content"><span className="fas fa-chevron-down fa-3x mt-5 text-white"></span></a>
                </div>
            </div>
        </header>

        <main>
            <div className="anchor" id="ss"></div>
            <div className="container my-4 story">
            {/* intro */}
            <section>
                <div className="row">
                    <Fade>
                    <div className="col-lg-10 text-center mx-auto lead mt-5" dangerouslySetInnerHTML={{
                        __html: data.intro.data.text.childMarkdownRemark.html,
                        }} />
                    </Fade>
                </div>
                <div className="row my-5">
                <Fade>
                    <div className="col-md-6 mb-3">
                        <Img fluid={data.introOne.data.file.localFiles[0].childImageSharp.fluid} alt={data.introOne.data.alt} />
                    </div>
                    <div className="col-md-6">
                        <Img fluid={data.introTwo.data.file.localFiles[0].childImageSharp.fluid} alt={data.introTwo.data.alt} />
                    </div>
                </Fade> 
                </div>
            </section>
        
            {/* A-House */}
            <section>
                <Fade>
                <h2>{data.b41.data.title}</h2>
                <div dangerouslySetInnerHTML={{
                    __html: data.b42.data.text.childMarkdownRemark.html,
                    }} />
                </Fade>
                <div className="row my-5">
                    <div className="col-md-6 mb-3 order-md-1">
                        <Img fluid={data.b43.data.file.localFiles[0].childImageSharp.fluid} alt={data.b43.data.alt} />
                    </div>
                    <Fade>
                    <div className="col-md-6 mb-3 order-md-2">
                        <Img fluid={data.b44.data.file.localFiles[0].childImageSharp.fluid} alt={data.b44.data.alt} />
                    </div>
                    </Fade>
                    <div className="col-md-6 mb-3 order-md-4">
                        <Img fluid={data.b46.data.file.localFiles[0].childImageSharp.fluid} alt={data.b46.data.alt} />
                    </div>
                    <Fade>
                    <div className="col-md-6 mb-3 order-md-3">
                        <Img fluid={data.b45.data.file.localFiles[0].childImageSharp.fluid} alt={data.b45.data.alt} />
                    </div>
                    </Fade>
                    <div className="col-md-6 mb-3 order-md-5">
                        <Img fluid={data.b47.data.file.localFiles[0].childImageSharp.fluid} alt={data.b47.data.alt} />
                    </div>
                    <Fade>
                    <div className="col-md-6 mb-3 order-md-6">
                        <Img fluid={data.b48.data.file.localFiles[0].childImageSharp.fluid} alt={data.b48.data.alt} />
                    </div>
                    </Fade>
                    <div className="col-md-6 mb-3 order-md-8">
                        <Img fluid={data.b410.data.file.localFiles[0].childImageSharp.fluid} alt={data.b410.data.alt} />
                    </div>
                    <Fade>
                    <div className="col-md-6 mb-3 order-md-7">
                        <Img fluid={data.b49.data.file.localFiles[0].childImageSharp.fluid} alt={data.b49.data.alt} />
                    </div>
                    </Fade>
                </div>
            </section>
            
            {/* House Guests */}
            <section>
                <h2>{data.b51.data.title}</h2>
                <div className="row mb-3">
                    <div className="col-lg-6 mb-lg-0 mb-3">
                    <Img fluid={data.b52.data.file.localFiles[0].childImageSharp.fluid} alt={data.b52.data.alt} />
                    </div>
                    <div className="col-lg-6 story center">
                    <div dangerouslySetInnerHTML={{
                            __html: data.b52.data.text.childMarkdownRemark.html,
                            }} />
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-lg-6 mb-3 order-lg-2">
                    <Img fluid={data.b53.data.file.localFiles[0].childImageSharp.fluid} alt={data.b53.data.alt} />
                    </div>
                    <div className="col-lg-6 mb-3 order-lg-1 story center">
                    <div dangerouslySetInnerHTML={{
                            __html: data.b53.data.text.childMarkdownRemark.html,
                            }} />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-lg-6 mb-lg-0 mb-3">
                    <Img fluid={data.b54.data.file.localFiles[0].childImageSharp.fluid} alt={data.b54.data.alt} />
                    </div>
                    <div className="col-lg-6 story center">
                    <div dangerouslySetInnerHTML={{
                            __html: data.b54.data.text.childMarkdownRemark.html,
                            }} />
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-lg-6 mb-3 order-lg-2">
                    <Img fluid={data.b55.data.file.localFiles[0].childImageSharp.fluid} alt={data.b55.data.alt} />
                    </div>
                    <div className="col-lg-6 mb-3 order-lg-1 story center">
                    <div dangerouslySetInnerHTML={{
                            __html: data.b55.data.text.childMarkdownRemark.html,
                            }} />
                    </div>
                </div>
            </section>
            
            {/* Soul Session */}
            <section>
                <Fade>
                <h2>{data.b61.data.title}</h2>
                <div dangerouslySetInnerHTML={{
                    __html: data.b62.data.text.childMarkdownRemark.html,
                    }} className="mb-5" />
                </Fade>
                <Fade bottom cascade>
                {data.b63.nodes.map(node => (
                <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} className="mb-3" alt={node.data.alt} key={node.recordId} />
                ))}
                </Fade>
            </section>
            
            {/* Kwanzaa */}
            <section>
                <Fade>
                <h2 className="mt-5">{data.b71.data.title}</h2>
                <div dangerouslySetInnerHTML={{
                    __html: data.b72.data.text.childMarkdownRemark.html,
                    }} className="mb-5" />
                </Fade>
                <Fade bottom cascade>
                {data.b73.nodes.map(node => (
                <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} className="mb-3" alt={node.data.alt} key={node.recordId} />
                ))}
                </Fade>
            </section>
            
            {/* Umoja */}
            <section>
                <Fade>
                <h2 className="mt-5">{data.b81.data.title}</h2>
                <div dangerouslySetInnerHTML={{
                    __html: data.b82.data.text.childMarkdownRemark.html,
                    }} className="mb-5" />
                </Fade>
                <Img fluid={data.b83.data.file.localFiles[0].childImageSharp.fluid} alt={data.b83.data.alt} />
            </section>
            
            {/* House Prayer */}
            <section>
                <Fade>
                <h2 className="mt-5">{data.b91.data.title}</h2>
                <div dangerouslySetInnerHTML={{
                    __html: data.b92.data.text.childMarkdownRemark.html,
                    }} className="mb-5" />
                </Fade>
                <Img fluid={data.b93.data.file.localFiles[0].childImageSharp.fluid} alt={data.b93.data.alt} />
            </section>
            
            {/* Contructing A-House */}
            <section>
                <h2 className="mt-5">{data.b101.data.title}</h2>
                <div dangerouslySetInnerHTML={{
                    __html: data.b102.data.text.childMarkdownRemark.html,
                    }} className="mb-5" />
                {/* <div id="carouselHouse" className="carousel slide" data-ride="carousel">
                    <ol className="carousel-indicators">
                        <li data-target="#carouselHouse" data-slide-to="0" className="active" key="recfymB3EWLnhZiB1"></li>
                        {data.b104.nodes.map(node => (
                        <li data-target="#carouselHouse" data-slide-to={node.data.order} key={node.recordId}></li>
                        ))}
                    </ol>
                <div className="carousel-inner">
                    {data.b103.nodes.map(node => (
                    <div className="carousel-item active" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} className="d-block w-100" alt={node.data.alt} />
                        <div className="carousel-caption d-none d-md-block">
                            <h3 className="h5 text-white">{node.data.title}</h3>
                            <div dangerouslySetInnerHTML={{
                    __html: node.data.text.childMarkdownRemark.html,
                    }} />
                        </div>
                    </div>
                    ))}
                    {data.b104.nodes.map(node => (
                    <div className="carousel-item" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} className="d-block w-100" alt={node.data.alt} />
                        <div className="carousel-caption d-none d-md-block">
                            <h3 className="h5 text-white">{node.data.title}</h3>
                            <div dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                        </div>
                    </div>
                    ))}
                </div>
                <a className="carousel-control-prev" href="#carouselHouse" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselHouse" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
                </div> */}
                <div className="mb-5">
                    <div className="row no-gutters">
                        {data.b103.nodes.map(node => (
                            <Fade key={node.recordId}>
                            <div className="col-lg-4 col-6">
                                <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} className="m-1" />
                                <div dangerouslySetInnerHTML={{
                                __html: node.data.text.childMarkdownRemark.html,
                                }} className="text-center small" aria-hidden="true" />
                            </div>
                            </Fade>
                        ))}
                    </div>
                </div>
            </section>
            {/* end container  */}
            </div> 

            {/* Credit */}
            <section>
                <div style={credit} role="img" aria-label={data.b111.data.alt} className="py-5 mb-0">
                    <div className="credit-text container text-right text-dark">
                        <p className="h3 credit-title text-dark">{data.b111.data.title}</p>
                        <div dangerouslySetInnerHTML={{
                                __html: data.b111.data.text.childMarkdownRemark.html,
                                }} />
                    </div>
                </div>
            </section>
        </main>
        </Layout>
    </div>
)

export const query = graphql`
{
    hero: airtable(data: {identifier: {eq: "recEyloetqAFhHQSM"}}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(fit: COVER, quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }

    title: airtable(data: {identifier: {eq: "recqFEGtybhQklNp3"}}) {
        data {
            title
        }
    }

    subtitle: airtable(data: {identifier: {eq: "recK1tA68PHJye6lP"}}) {
        data {
            title
        }
    }

    intro: airtable(data: {identifier: {eq: "recJSAKt1qkX429fx"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    introOne: airtable(data: {identifier: {eq: "recDU3MCsJVp6hlxM"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxWidth: 500) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    introTwo: airtable(data: {identifier: {eq: "recFEUln5tuxleZBR"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    b41: airtable(data: {identifier: {eq: "recbJKcLjQ4ddx9an"}}) {
        data {
            title
        }
    }

    b42: airtable(data: {identifier: {eq: "recJPTAteDGHeDjVn"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    b43: airtable(data: {identifier: {eq: "recUtrph5oroAmaWo"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxWidth: 500, maxHeight: 350, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    b44: airtable(data: {identifier: {eq: "recvA0Pc3HcqKykaX"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxWidth: 500, maxHeight: 350, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    b45: airtable(data: {identifier: {eq: "recdGJazK8GkT4sDI"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxWidth: 500, maxHeight: 350, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    b46: airtable(data: {identifier: {eq: "recIDyEOHngftocEh"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxWidth: 500, maxHeight: 350, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    b47: airtable(data: {identifier: {eq: "recovHEnUqDM5J2ge"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxWidth: 500, maxHeight: 350, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    b48: airtable(data: {identifier: {eq: "recROKRA6yvISN2Ni"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxWidth: 500, maxHeight: 350, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    b49: airtable(data: {identifier: {eq: "recJrqm7kCrFu0x2T"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxWidth: 500, maxHeight: 350, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    b410: airtable(data: {identifier: {eq: "recZM3ubOrWv7HHDc"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxWidth: 500, maxHeight: 350, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    b51: airtable(data: {identifier: {eq: "recrOyR4G9G5MyN9Z"}}) {
        data {
            title
        }
    }

    b52: airtable(data: {identifier: {eq: "recp0jKazWTYYWT66"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    b53: airtable(data: {identifier: {eq: "recgoZIAwL4mSf5uZ"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    b54: airtable(data: {identifier: {eq: "recCICNg3khK012OG"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    b55: airtable(data: {identifier: {eq: "recqRikXStM2uKjyn"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    b61: airtable(data: {identifier: {eq: "reczlFP0rjXpRsYB8"}}) {
        data {
            title
        }
    }

    b62: airtable(data: {identifier: {eq: "recm3AeATnLBw2JjU"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    b63: allAirtable(filter: {data: {group: {eq: "ahh6"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                alt
                file {
                    localFiles {
                        childImageSharp {
                            fluid(maxHeight: 600, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }

    b71: airtable(data: {identifier: {eq: "rec8TjarJS33j8aea"}}) {
        data {
            title
        }
    }

    b72: airtable(data: {identifier: {eq: "recxjCDcoGCDwehkQ"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    b73: allAirtable(filter: {data: {group: {eq: "ahh7"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                alt
                file {
                    localFiles {
                        childImageSharp {
                            fluid(maxHeight: 600, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }

    b81: airtable(data: {identifier: {eq: "rec3ybmg93mi6vk8J"}}) {
        data {
            title
        }
    }

    b82: airtable(data: {identifier: {eq: "recwAvRWIVfdQUO1M"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    b83: airtable(data: {identifier: {eq: "recYWGXDSy62WRo50"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxWidth: 1200, maxHeight: 600, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    b91: airtable(data: {identifier: {eq: "recmtIDAkfJJo4JV9"}}) {
        data {
            title
        }
    }

    b92: airtable(data: {identifier: {eq: "recwsthuoTyyoXrTw"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    b93: airtable(data: {identifier: {eq: "rec9Q6b0rJ2V81HBA"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxWidth: 1200, maxHeight: 600, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    b101: airtable(data: {identifier: {eq: "recFBNBCgGqgyNSqe"}}) {
        data {
            title
        }
    }

    b102: airtable(data: {identifier: {eq: "recpgTn1euSX4vXwu"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    b103: allAirtable(filter: {data: {group: {eq: "ahh10"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
                title
                file {
                    localFiles {
                        childImageSharp {
                            fluid(maxHeight: 400, maxWidth: 600, cropFocus: ATTENTION, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }

    b104: allAirtable(filter: {data: {group: {eq: "ahh10"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
                title
                alias
                order
                file {
                    localFiles {
                        childImageSharp {
                            fluid(maxHeight: 700, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }

    b111: airtable(recordId: {eq: "recvPCyfBxT5tu2QV"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
            title
            alt
        }
    }

    masonry: allAirtable(filter: {data: {story: {eq: "Welcome to the House"}, element_type: {eq: "grid"}}}) {
        nodes {
            data {
                file {
                    localFiles {
                        childImageSharp {
                                fluid(cropFocus: ATTENTION, maxHeight: 500, maxWidth: 500, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                alt
            }
        }
    }
}  
`;